import React from 'react'
import styles from './productDetails.module.less'
import TestDetailImg from '@/assets/img/product_details/test_detail.jpg'

const ProductDetails = ()=> {
    return (
        <div className={styles.productDetailsPage}>
            <div className={styles.content}>
                <div className={styles.topBox}>
                    <div className={styles.productImg}>
                        <img src={TestDetailImg} alt="product" />
                    </div>
                    <div className={styles.productInfo}>
                        <div className={styles.productName}>给小朋友的AI课</div>
                        <div className={styles.productPrice}>价格：499元</div>
                        <div className={styles.buyBtn}>立即购买</div>
                    </div>
                </div>
                <div className={styles.detailBox}>
                    <div className={styles.detailTitle}>商品详情</div>
                    <div className={styles.detailContent}>
                        是一个风格类似多邻国的教小学生学习使用大模型的课程-《给孩子们的AI课》。未来的社会是AGI无处不在的社会，今天的小朋友如果能更早地接触大模型，更早地懂得使用大模型，能极大地打开小朋友的眼界，启迪他们的思维，同时更能对他们平常的学习和生活带来很大的帮助。但目前市面上的教授大模型使用技巧的产品，主要都是面对成年人。我们的产品是目前市场上极其稀缺的面对小学生的这类型教学产品。
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductDetails;
