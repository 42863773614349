// translations.js  
export const translations = {
    en: {
        product_name: "AiBo AI",
        product_intro_btn:"Product Introduction",
        about_us: "About Us",
        try_button: "Try for Free",
        module1_title: "Welcome to AiBo AI",
        module1_content: "An AI learning tool for kids, designed to spark interest in AI and enhance learning efficiency with advanced technology",
        module2_title: "Project Based Learning",
        module2_content: "Each class solves a practical problem, boosting children's problem-solving skills",
        module3_title: "Personalized interactive content",
        module3_content: "Children can choose their favorite content, and AI teachers can conduct open-ended interactions.",
        module4_title: "Teach principles and cultivate innovation",
        module4_content: "Explain the big model to children in a simple and understandable way, practice first principles, and learn to think and criticize",
        bottom_text: "© Copyright 2023 NeuronForce. All Rights Reserved."
    },
    zh: {
        product_name: "艾宝AI",
        product_intro_btn: "产品介绍",
        about_us: "关于我们",
        try_button: "免费体验",
        module1_title: "Hi~~欢迎来到艾宝AI",
        module1_content: "专门为8-15岁孩子设计的AI学习工具，它结合了最新的人工智能技术，旨在激发孩子们对AI产品的兴趣，提高学习效率。",
        module2_title: "项目制学习 PBL",
        module2_content: "每节课解决一个生活中的实际问题,通过实践自主学习、加深记忆，提升解决问题的能力。",
        module3_title: "个性化互动内容",
        module3_content: "孩子能自己选择更喜欢的内容,AI老师能进行开放性互动",
        module4_title: "教授原理，培养创新",
        module4_content: "深入浅出地给小朋友讲明白大模型,实践第一性原理，学会思考和批判",
        bottom_text: "2024 NeuronForce. 保留所有权利."
    }
};