import { useEffect, useState } from 'react';
import styles from './course.module.less';
import { useNavigate } from 'react-router-dom';
import service from '@/services/axios';
import { Button, Modal, message } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import VisibleToTeacher from '@/components/VisibleToTeacher/VisibleToTeacher';
import { observer } from 'mobx-react-lite';
import { useStores } from '@/store/useStores';
import ChangeViewLayout from '@/pages/Layouts/ChangeViewLayout/ChangeViewLayout';
import UploadMediaBulk from '@/components/UploadMedia/UploadMediaBulk';
import CreateCourseModal from '@/components/CreateCourseModal/CreateCourseModal';
import CreateSectionModal from '@/components/CreateSectionModal/CreateSectionModal';
import VisibleToStudent from '@/components/VisibleToStudent/VisibleToStudent';
import { cloneDeep, set } from 'lodash';
import ClearRecordModal from '@/components/ClearRecordModal/ClearRecordModal';
import mixpanel from 'mixpanel-browser';
import { CourseData } from '@/base/CourseData';
import { ChapterData } from '@/base/ChapterData';
import ProgressSegmentData from '@/base/ProgressSegmentData';
import KeyPointIcon from '@/assets/img/key_point_icon.svg';
import { SectionData } from '@/base/SectionData';
import { genCourseDatafromJSON, genSectionDatafromJSON } from '@/base/DataObjectGenerator';

const Course: React.FC = observer(() => {
	let navigate = useNavigate();
	const [courseList, setCourseList] = useState<CourseData[]>([]);
	const [sectionList, setSectionList] = useState<SectionData[]>([]);
	// level 0是course, level 1是chapter
	const [currentLevel, setCurrentLevel] = useState(localStorage.getItem('currentLevel') ? parseInt(localStorage.getItem('currentLevel')!) : 0);
	const [inputCourseName, setInputCourseName] = useState('');//创建输入的课程名字
	const [inputCourseIntro, setInputCourseIntro] = useState('');//创建输入的课程介绍
	const [courseCoverUrl, setCourseCoverUrl] = useState('');//课程封面url
	const [inputSectionName, setInputSectionName] = useState('');//创建输入的课程名字
	const [inputSectionIntro, setInputSectionIntro] = useState('');//创建输入的课程介绍
	const [sectionCoverUrl, setSectionCoverUrl] = useState('');//课程封面url

	const [isCreating, setIsCreating] = useState(false); // 用于追踪课程创建的加载状态
	const [courseModalType, setCourseModalType] = useState('create'); // 'create' 或 'edit' 
	const [sectionModalType, setSectionModalType] = useState('create');
	const [isUploadModalVisible, setIsUploadModalVisible] = useState(false);
	const [currentCourse, setCurrentCourse] = useState<CourseData | null>(localStorage.getItem("currentCourse") ? JSON.parse(localStorage.getItem("currentCourse")!) : null);
	const [currentChapter, setCurrentChapter] = useState<ChapterData | null>(null);
	const [currentSection, setCurrentSection] = useState<SectionData | null>(null);
	const [keyPoints, setKeyPoints] = useState<string[]>([]); //课程关键点, 最多3个
	const [partSegments, setPartSegments] = useState<ProgressSegmentData[]>([]);//课程分段信息
	const [courseVisibleToNormalUsers, setCourseVisibleToNormalUsers] = useState(true);
	const [sectionVisibleToNormalUsers, setSectionVisibleToNormalUsers] = useState(true);//是否对普通用户可见
	const [sectionBGM, setSectionBGM] = useState<string>('');	//课程背景音乐
	const { confirm } = Modal;
	const { userInfoStore, lectureStore } = useStores();

	//Modal
	const [isCourseModalOpen, setIsCourseModalOpen] = useState(false);
	const [isSectionModalOpen, setIsSectionModalOpen] = useState(false);


	//useEffect(() => {
	// 这里可以进行一些操作，比如从服务器获取数据并更新 partSegments
	// setPartSegments(newData);
	// 最好目前不要删除这个console log
	//  	console.log("partSegments in Course useEffect", partSegments);
	//}, [partSegments]);

	const showCourseModal = (type: string, course: CourseData | null) => {
		if (course) {
			if (course.extras?.visibleToNormalUsers === undefined) {
				course.extras.visibleToNormalUsers = true;
			}
			setCourseVisibleToNormalUsers(course.extras.visibleToNormalUsers);

		}
		setCurrentCourse(course);
		setCourseModalType(type);
		if (type === 'edit') {
			setInputCourseName(course!.name);
			setInputCourseIntro(course!.extras ? course!.extras.introduce : '');
			setCourseCoverUrl(course!.extras ? course!.extras.cover : '');
		} else if (type === 'create') {
			setInputCourseName('');
			setInputCourseIntro('');
			setCourseCoverUrl('');
			setCourseVisibleToNormalUsers(true);
		}
		setIsCourseModalOpen(true);
	};

	const showSectionModal = (type: string, section: SectionData | null) => {
		if (section) {
			if (section.extras?.partSegments !== undefined && section.extras?.partSegments.length > 0) {
				let partSegments = [];
				partSegments = section.extras?.partSegments.map((segment: ProgressSegmentData) => {
					return new ProgressSegmentData({ ...segment });
				});
				setPartSegments(partSegments);
			} else {
				setPartSegments(section.extras.partSegments);
			}
			if (section.extras?.visibleToNormalUsers === undefined) {
				section.extras.visibleToNormalUsers = true;
			}
			setKeyPoints(section.extras.keyPoints || []);
			setSectionVisibleToNormalUsers(section.extras.visibleToNormalUsers);
			setSectionBGM(section.extras.bgm || '');

		}
		setCurrentSection(section);
		setSectionModalType(type);
		if (type === 'edit') {
			setInputSectionName(section!.name);
			setInputSectionIntro(section!.extras ? section!.extras.introduce : '');
			setSectionCoverUrl(section!.extras ? section!.extras.cover : '');
		} else if (type === 'create') {
			setInputSectionName('');
			setInputSectionIntro('');
			setSectionCoverUrl('');
			setPartSegments([]);
			setSectionBGM('');
			setKeyPoints([]);
			setSectionVisibleToNormalUsers(true);
		}
		setIsSectionModalOpen(true);
	};

	const handleCourseOk = async () => {
		if (courseModalType === 'create') {
			await createCourse();
		} else if (courseModalType === 'edit') {
			await updateCourse();
		}
		setIsCourseModalOpen(false);
		setInputCourseName('');
		setInputCourseIntro('');
		setCourseCoverUrl('');
		setCourseVisibleToNormalUsers(true);
	};

	const handleCourseCancel = () => {
		setIsCourseModalOpen(false);
		setInputCourseName('');
		setInputCourseIntro('');
		setCourseCoverUrl('');
		setCourseVisibleToNormalUsers(true);
	};

	const handleSectionOk = async (segments: any) => {
		if (sectionModalType === 'create') {
			await createSection();
		} else if (sectionModalType === 'edit') {
			await updateSection();
		}
		setIsSectionModalOpen(false);
		setInputSectionName('');
		setInputSectionIntro('');
		setSectionCoverUrl('');
		setPartSegments(segments);
		setSectionVisibleToNormalUsers(true);
	}

	const handleSectionCancel = () => {
		setIsSectionModalOpen(false);
		setInputSectionName('');
		setInputSectionIntro('');
		setSectionCoverUrl('');
		setSectionVisibleToNormalUsers(true);
	}

	const handleUploadModalOk = () => {
		setIsUploadModalVisible(false);
	};

	const handleUploadModalCancel = () => {
		setIsUploadModalVisible(false);
	};


	const onUploadSuccess = (file_url: string) => {
		console.log(`${file_url} uploaded successfully`);
		/*if (info.file.status === 'done') {
		message.success(`${info.file.name} file uploaded successfully`);
		console.log(`${info.file.name} file uploaded successfully`);
		} else if (info.file.status === 'error') {
		message.error(`${info.file.name} file upload failed.`);
		console.log(`${info.file.name} file upload failed.`);
		}*/
	};

	const goLessonCourse = async (course: CourseData) => {
		// get all the chapters and sections
		// 调用getSectionList， set the sectionList
		setCurrentCourse(course);
		console.log("goLessonCourse", course);
		getSectionList(course.id);
		//进入 level 1， sections页面
		setCurrentLevel(1);
		localStorage.setItem('currentLevel', '1');
		localStorage.setItem("currentCourseId", course.id.toString());
		localStorage.setItem("currentCourseName", course.name);
		localStorage.setItem("currentCourse", JSON.stringify(course));
	}


	const goLessonSection = async (courseId: number, chapterId: number, section: SectionData) => {
		console.log("goLessonSection", courseId, chapterId, section);

		// 需要setPartSegments
		if (import.meta.env.PROD) {
			mixpanel.track('goLessonSection', {
				'courseId': courseId,
				'sectionId': section.id,
			});
		}

		console.log("goLessonSection", section.id, section.name);

		const partSegments: ProgressSegmentData[] = [];
		if (section.extras.partSegments) {
			for (let i = 0; i < section.extras.partSegments.length; i++) {
				partSegments.push(new ProgressSegmentData({
					endPos: section.extras.partSegments[i].endPos,
					desc: section.extras.partSegments[i].desc
				}));
			}
		}

		lectureStore.setPartSegments(partSegments);
		if (section.extras.bgm) {
			lectureStore.setCourseBGM(section.extras.bgm);
		} else {
			lectureStore.setCourseBGM('');
		}
		navigate(`/coursestudy/${courseId}/${chapterId}/${section.id}`);
	};


	//获取输入框内容
	const courseNameChange = (e: any) => {
		setInputCourseName(e.target.value); // 使用事件对象e中的value属性来更新状态
	};

	const courseIntroChange = (e: any) => {
		setInputCourseIntro(e.target.value);
	};

	const courseCoverUrlChange = (url: string) => {
		setCourseCoverUrl(url);
	};

	const courseVisibleToNormalUsersChange = (value: boolean) => {
		setCourseVisibleToNormalUsers(value);
	}

	const sectionNameChange = (e: any) => {
		setInputSectionName(e.target.value);
	};

	const sectionIntroChange = (e: any) => {
		setInputSectionIntro(e.target.value);
	};

	const sectionKeyPointChange = (index: number, value: string) => {
		const newKeyPoints = cloneDeep(keyPoints);
		newKeyPoints[index] = value;
		setKeyPoints(newKeyPoints);
	};

	const sectionCoverUrlChange = (url: string) => {
		setSectionCoverUrl(url);
	};

	const sectionPartSegmentsChange = (index: number, key: string, value: any) => {
		let segments: ProgressSegmentData[] = cloneDeep(partSegments);
		if (key === 'new') {
			// 这里好像有些异步的问题
			if (segments === undefined) {
				segments = [];
			}
			segments.push(new ProgressSegmentData({ endPos: 0, desc: '' }));
		} else if (key === 'del') {
			segments.splice(index, 1);
		} else {
			segments[index].setField((key as keyof ProgressSegmentData), value);
		}
		setPartSegments(segments);
	};

	const sectionVisibleToNormalUsersChange = (value: boolean) => {
		setSectionVisibleToNormalUsers(value);
	}

	const sectionBGMChange = (url: string) => {
		setSectionBGM(url);
	}

	// 获取课程列表
	const getCourseList = async () => {
		try {
			const response: any = await service.get('/course_all/', { params: { mode: 1 } });//0不详细内容 1全部内容
			if (response.status === 'success') {
				const cList: CourseData[] = [];
				for (let i = 0; i < response.data.length; i++) {
					cList.push(genCourseDatafromJSON(response.data[i]));
				}
				setCourseList(cList);
			} else {
				message.error('请求出错')
				setCourseList([]);
			}
		} catch (error) {
			console.error("Failed to fetch course list:", error);
		}
	}

	const getSectionList = async (courseId: number) => {
		try {
			const response: any = await service.get(`/course/${courseId}`);
			if (response.status === 'success') {
				const sList: SectionData[] = [];
				for (let i = 0; i < response.data.chapters[0].sections.length; i++) {
					sList.push(genSectionDatafromJSON(response.data.chapters[0].sections[i]));
				}
				setSectionList(sList);
				setCurrentChapter(response.data.chapters[0]);
			} else {
				message.error('请求出错')
				setSectionList([]);
				setCurrentChapter(null);
			}
		} catch (error) {
			console.error("Failed to fetch course list:", error);
		}
	}

	const goBackToCourseHall = async () => {
		setCurrentLevel(0);
		localStorage.setItem('currentLevel', '0');
		localStorage.removeItem('currentCourseId');
		localStorage.removeItem('currentCourseName');
		localStorage.removeItem('currentCourse');
		setCurrentCourse(null);
		setCurrentChapter(null);
		setSectionList([]);
		getCourseList();
	}

	// ################### Course Level Ops ####################
	// 创建课程
	const createCourse = async () => {
		if (inputCourseName.trim() === '' || inputCourseIntro.trim() === '' || courseCoverUrl.trim() === '') {
			message.error("请填写完整信息");
			return;
		}
		const extras = {
			cover: courseCoverUrl,
			introduce: inputCourseIntro,
			visibleToNormalUsers: courseVisibleToNormalUsers,
		};
		const courseParams = new CourseData({
			name: inputCourseName,
			extras: extras
		});
		setIsCreating(true); // 开始创建，设置isLoading为true
		try {
			console.log("courseParams", courseParams.toJSON());
			const response: any = await service.post('/course_express', courseParams.toJSON());
			if (response.status === "success") {
				// 课程创建成功，更新课程列表
				await getCourseList();
			} else {
				console.log("Course creation was not successful");
				// 处理未成功创建的情况
			}
		} catch (error) {
			console.error("Failed to create course:", error);
			// 处理发生的错误
		} finally {
			setIsCreating(false); // 请求完成，无论成功或失败，关闭 loading 状态
		}
	};
	//修改课程名
	const updateCourse = async () => {
		console.log("updateCourse", currentCourse);
		if (currentCourse === null || (inputCourseName.trim() === '' && inputCourseIntro.trim() === '' && courseCoverUrl.trim() === '')) {
			return; // 确保有课程ID和非空的课程名
		}
		setIsCreating(true); // 开始请求前显示加载状态
		try {
			currentCourse.name = inputCourseName;
			currentCourse.extras = {
				cover: courseCoverUrl,
				introduce: inputCourseIntro,
				visibleToNormalUsers: courseVisibleToNormalUsers
			};

			const response: any = await service.put('/course', currentCourse.toJSON());
			if (response.status === "success") {
				// 写入lectureStore
				lectureStore.setPartSegments(partSegments);
				// 课程修改成功，重新获取课程列表
				await getCourseList();
			} else {
				message.error("修改失败");
				console.error("Course update was not successful");
				// 处理未成功修改的情况
			}
		} catch (error) {
			console.error("Failed to update course:", error);
			// 处理发生的错误
		} finally {
			setIsCreating(false); // 关闭加载状态
			setIsCourseModalOpen(false); // 关闭Modal
			setCurrentCourse(null); // 清空正在编辑的课程
		}
	};


	//删除课程
	const deleteCourse = async (courseId: number) => {
		confirm({
			title: '确认删除课程?',
			content: '删除后将无法找回这个课程，你确定要删除吗？',
			async onOk() {
				try {
					const response: any = await service.delete(`/course/${courseId}`);
					if (response.status === "success") {
						// 课程删除成功，重新获取课程列表
						await getCourseList();
					} else {
						message.error("删除失败");
						console.error("Course delete was not successful");
						// 处理未成功修改的情况
					}
				} catch (error) {
					console.error("Failed to delete course:", error);
					// 处理发生的错误
				}
			},
			centered: true,
			okText: '确认',
			cancelText: '取消'
		});
	}

	//复制课程
	const copyCourse = async (courseId: number) => {
		try {
			const response: any = await service.post(`/course_dup/${courseId}`);
			if (response.status === "success") {
				// 课程复制成功，重新获取课程列表
				await getCourseList();
			} else {
				message.error("复制失败");
				console.error("Course copy was not successful");
				// 处理未成功修改的情况
			}
		} catch (error) {
			console.error("Failed to copy course:", error);
			// 处理发生的错误
		}
	}

	const publishCourse = async (courseId: number) => {
		console.log("publishCourse", courseId);
		try {
			const response: any = await service.post(`/publish_course/${courseId}`);
			if (response.status === "success") {
				// 课程复制成功，重新获取课程列表
				//await getCourseList();
			} else {
				message.error("publish失败");
				console.error("Course publish was not successful");
				// 处理未成功修改的情况
			}
		} catch (error) {
			console.error("Failed to publish course:", error);
			// 处理发生的错误
		}

	}

	// ####################  Section Level ####################
	const createSection = async (
	) => {
		if (inputSectionName.trim() === '' || inputSectionIntro.trim() === ''
			|| sectionCoverUrl.trim() === '') {
			message.error("请填写完整信息");
			return;
		}
		const extras = {
			cover: sectionCoverUrl,
			introduce: inputSectionIntro,
			partSegments: partSegments.map(segment => ({
				endPos: segment.endPos,
				lengthPercent: segment.lengthPercent,
				progress: segment.progress,
				desc: segment.desc
			})),
			visibleToNormalUsers: sectionVisibleToNormalUsers,
			keyPoints: keyPoints,
			bgm: sectionBGM
		};
		const sectionData = new SectionData({
			name: inputSectionName,
			extras: extras
		});

		setIsCreating(true); // 开始创建，设置isLoading为true
		const payload = {
			chapter_id: currentChapter!.id,
			section: sectionData.toJSON()
		};

		try {
			const response: any = await service.put('/chapter_add_sec', payload);
			if (response.status === "success") {
				// 课程创建成功，更新课程列表
				await getSectionList(currentCourse!.id);
			} else {
				console.log("section creation was not successful");
				// 处理未成功创建的情况
			}
		} catch (error) {
			console.error("Failed to create section:", error);
			// 处理发生的错误
		} finally {
			setIsCreating(false); // 请求完成，无论成功或失败，关闭 loading 状态
		}
	};

	const updateSection = async (
	) => {
		if (currentSection === null || (inputSectionName.trim() === '' && inputSectionIntro.trim() === '' && sectionCoverUrl.trim() === '')) {
			return; // 确保有课程ID和非空的课程名
		}
		setIsCreating(true); // 开始请求前显示加载状态
		try {
			currentSection.name = inputSectionName;
			currentSection.extras = {
				cover: sectionCoverUrl,
				introduce: inputSectionIntro,
				partSegments: partSegments.map(segment => ({
					endPos: segment.endPos,
					lengthPercent: segment.lengthPercent,
					progress: segment.progress,
					desc: segment.desc
				})),
				visibleToNormalUsers: sectionVisibleToNormalUsers,
				keyPoints: keyPoints,
				bgm: sectionBGM
			};

			console.log("updateSection", currentSection.toJSON());
			const response: any = await service.put('/section', currentSection.toJSON());
			if (response.status === "success") {
				// 写入lectureStore
				lectureStore.setPartSegments(partSegments);
				// 课程修改成功，重新获取课程列表
				console.log("currentCourse", currentCourse);
				await getSectionList(currentCourse!.id);
			} else {
				message.error("修改失败");
				console.error("Course update was not successful");
				// 处理未成功修改的情况
			}
		} catch (error) {
			console.error("Failed to update course:", error);
			// 处理发生的错误
		} finally {
			setIsCreating(false); // 关闭加载状态
			setIsSectionModalOpen(false); // 关闭Modal
			setCurrentSection(null); // 清空正在编辑的课程
			setPartSegments([]); // 清空正在编辑的partSegments
			setKeyPoints([]); // 清空正在编辑的keyPoints
			setSectionBGM(''); // 清空正在编辑的BGM
			setSectionCoverUrl(''); // 清空正在编辑的coverUrl
			setSectionVisibleToNormalUsers(true); // 清空正在编辑的visibleToNormalUsers
			setInputSectionIntro(''); // 清空正在编辑的intro
			setInputSectionName(''); // 清空正在编辑的name
		}
	};

	const deleteSection = async (sectionId: number) => {
		confirm({
			title: '确认删除Section?',
			content: '删除后将无法找回这个Section，你确定要删除吗？',
			async onOk() {
				try {
					if (currentChapter === null || currentCourse === null) {
						console.error("currentChapter or currentCourse is null");
						return;
					}
					const payload = {
						chapter_id: currentChapter!.id,
						section_id: sectionId
					};
					const response: any = await service.put("/chapter_remove_sec", payload);
					if (response.status === "success") {
						// 课程删除成功，重新获取课程列表
						await getSectionList(currentCourse!.id);
					} else {
						message.error("删除失败");
						console.error("Course delete was not successful");
						// 处理未成功修改的情况
					}
				} catch (error) {
					console.error("Failed to delete course:", error);
					// 处理发生的错误
				}
			},
			centered: true,
			okText: '确认',
			cancelText: '取消'
		});
	}

	const shallShow = (data: CourseData | SectionData, role: number) => {
		return data.extras.visibleToNormalUsers || (!data.extras.visibleToNormalUsers && role === 1);
	};

	const visibleCoursesList = courseList.filter(course => shallShow(course, userInfoStore.userInfoData.userRole));
	const visibleSectionsList = sectionList.filter(section => shallShow(section, userInfoStore.userInfoData.userRole));

	useEffect(() => {
		getCourseList();
	}, []);

	useEffect(() => {
		if (localStorage.getItem('currentLevel') === '1') {
			getSectionList(parseInt(localStorage.getItem('currentCourseId')!));
		}
	}, [])

	return (
		<>
			<div className={styles.container}>
				{
					userInfoStore.userInfoData.userRole === 1 &&
					<div className={styles.headBar}>
						<ChangeViewLayout />
						<VisibleToTeacher>
							<div className={styles.createCourse}>
								{currentLevel === 0 &&
									<Button size='large' className={styles.createBtn} onClick={() => showCourseModal('create', null)}>创建课程</Button>
								}
								{currentLevel === 1 &&
									<Button size='large' className={styles.createBtn} onClick={() => showSectionModal('create', null)}>创建Section</Button>
								}
							</div>
						</VisibleToTeacher>
						<VisibleToTeacher>
							<div className={styles.createCourse}>
								<Button size='large' className={styles.createBtn} onClick={() => setIsUploadModalVisible(true)}>上传文件</Button>
								<UploadMediaBulk isVisible={isUploadModalVisible}
									successCallBack={onUploadSuccess}
									handleOk={handleUploadModalOk}
									handleCancel={handleUploadModalCancel}
								/>
							</div>
						</VisibleToTeacher>
						{currentLevel === 1 &&
							<Button size='large' className={styles.createBtn} onClick={() => goBackToCourseHall()}>返回课程大厅</Button>
						}
					</div>
				}
				<div className={styles.contentBox}>
					<div className={styles.title}>
						{currentLevel === 0 && "你好啊，欢迎来到艾宝AI"}
						{currentLevel === 1 && "你好啊，让我们学习《" + `${localStorage.getItem("currentCourseName") || currentCourse?.name}` + "》吧！"}
					</div>
					<div className={styles.introduce}>
						{currentLevel === 0 && "我是你的学习伙伴，在这里我们将一起掌握并了解AI的知识"}
					</div>
					<div className={styles.courseListBox}>
						{currentLevel === 0 && visibleCoursesList?.map(item => (
							<div key={item.id} className={styles.courseCardBox}>
								<div className={styles.courseCard}>
									<div className={styles.courseImg} style={{ background: item?.extras?.cover ? '#ffffff' : "" }}>
										<img src={item?.extras?.cover} alt="" />
									</div>
									<div className={styles.content}>
										<div className={styles.topBox}>
											<div className={styles.courseName}>{item.name}</div>
											<div className={styles.courseIntroduce}>
												{item?.extras?.introduce}
											</div>
										</div>
										<button className={styles.startLearnBtn} onClick={() => goLessonCourse(item)}>
											<VisibleToStudent>进入课程</VisibleToStudent>
											<VisibleToTeacher>去编辑Sections</VisibleToTeacher>
										</button>
									</div>
									<VisibleToTeacher>
										<div className={styles.delBtn}
											onClick={(e) => {
												e.stopPropagation();
												deleteCourse(item.id)
											}}>
											{<DeleteOutlined />}
										</div>
									</VisibleToTeacher>
								</div>
								<VisibleToTeacher>
									<div className={styles.updateBtn}>
										<Button onClick={() => showCourseModal('edit', item)}>修改课程信息</Button>
										<Button onClick={() => copyCourse(item.id)}>复制课程</Button>
										<Button onClick={() => publishCourse(item.id)}>publish到{
											import.meta.env.PROD ? "Test环境" : "Prod环境"}</Button>
									</div>
								</VisibleToTeacher>
							</div>
						))}
						{/* 单数时候加一个空白的div */}
						{currentLevel === 0 && visibleCoursesList.length % 2 === 1 && <div className={styles.courseCardBoxEmpty}></div>}

						{/* ======= 以下是section view ======= */}
						{currentLevel === 1 && visibleSectionsList?.map(item => (
							<div key={item.id} className={styles.courseCardBox}>
								<div className={styles.courseCard}>
									<div className={styles.courseImg} style={{ background: item?.extras?.cover ? '#ffffff' : "" }}>
										<img src={item?.extras?.cover} alt="" />
									</div>
									<div className={styles.content}>
										<div className={styles.topBox}>
											<div className={styles.courseName}>{item.name}</div>
											<div className={styles.courseIntroduce}>
												{item?.extras?.introduce}
											</div>
											<div className={styles.keyPoints}>
												<div className={styles.keyPointTitle}>
													<img src={KeyPointIcon} alt="" className={styles.keyPointIcon} />
													课程目标
												</div>
												<div className={styles.keyPointContent}>
													{item?.extras?.keyPoints?.map((keyPoint: string, index: number) => (
														<div key={index} className={styles.pointItem}>
															{keyPoint}
															<div className={styles.circleIcon}></div>
															<div className={styles.lineIcon}></div>
														</div>
													))}
												</div>
											</div>
										</div>

										<button className={styles.startLearnBtn} onClick={() => goLessonSection(currentCourse!.id, currentChapter!.id, item)}>
											<VisibleToStudent>开始学习</VisibleToStudent>
											<VisibleToTeacher>编辑课程</VisibleToTeacher>
										</button>
									</div>
									<VisibleToTeacher>
										<div className={styles.delBtn}
											onClick={(e) => {
												e.stopPropagation();
												deleteSection(item.id)
											}}>
											{<DeleteOutlined />}
										</div>
									</VisibleToTeacher>
									<VisibleToStudent>
										<div className={styles.clearBtn} onClick={
											(e) => {
												e.stopPropagation();
												e.preventDefault();
											}
										}>
											<ClearRecordModal
												userId={userInfoStore.userInfoData.id}
												sectionId={item.id}
												mode={userInfoStore.userInfoData.userRole === 1 ? "teacher" : "student"}
											/>
										</div>
									</VisibleToStudent>
								</div>
								<VisibleToTeacher>
									<div className={styles.updateBtn}>
										<Button onClick={() => showSectionModal('edit', item)}>修改课信息</Button>
									</div>
								</VisibleToTeacher>
							</div>
						))}
						{/* 单数时候加一个空白的div */}
						{currentLevel === 1 && visibleSectionsList.length % 2 === 1 && <div className={styles.courseCardBoxEmpty}></div>}
					</div>
				</div>
			</div>
			<CreateCourseModal
				isModalOpen={isCourseModalOpen}
				handleCourseOk={handleCourseOk}
				handleCourseCancel={handleCourseCancel}
				inputCourseName={inputCourseName}
				inputCourseIntro={inputCourseIntro}
				courseCoverUrl={courseCoverUrl}
				courseVisibleToNormalUsers={courseVisibleToNormalUsers}
				courseNameChange={courseNameChange}
				courseCoverUrlChange={courseCoverUrlChange}
				courseIntroduceChange={courseIntroChange}
				courseVisibleToNormalUsersChange={courseVisibleToNormalUsersChange}
				isCreating={isCreating}
			/>
			<CreateSectionModal
				isModalOpen={isSectionModalOpen}
				handleSectionOk={handleSectionOk}
				handleSectionCancel={handleSectionCancel}
				inputSectionName={inputSectionName}
				inputSectionIntro={inputSectionIntro}
				sectionCoverUrl={sectionCoverUrl}
				sectionKeyPoints={keyPoints}
				sectionPartSegments={partSegments}
				sectionVisibleToNormalUsers={sectionVisibleToNormalUsers}
				sectionBGM={sectionBGM}
				sectionNameChange={sectionNameChange}
				sectionCoverUrlChange={sectionCoverUrlChange}
				sectionIntroduceChange={sectionIntroChange}
				sectionKeyPointChange={sectionKeyPointChange}
				sectionPartSegmentsChange={sectionPartSegmentsChange}
				sectionVisibleToNormalUsersChange={sectionVisibleToNormalUsersChange}
				sectionBGMChange={sectionBGMChange}
				totalBlocks={currentCourse?.chapters[0]?.sections[0]?.blks.length || 0}
				isCreating={isCreating}
			/>
		</>
	);
});

export default Course;