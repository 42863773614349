import React, { useEffect, useState } from 'react';
import styles from "./myWorks.module.less";
import { useNavigate } from 'react-router-dom';
import GoBackIcon from '@/assets/img/user_center/go_back.svg';
import { observer } from 'mobx-react-lite';
import { useStores } from '@/store/useStores';
import { getAllUserWorks } from '@/api/userWork';
import UserWorksListIcon from '@/assets/img/user_center/user_works_list_icon.svg'

const MyWorks: React.FC = () => {
    const navigate = useNavigate();
    const { userInfoStore } = useStores();
    const [worksList, setWorksList] = useState<any[]>([]);

    // 获取所有的作品
    const getAllWorks = async () => {
        try {
            const res: any = await getAllUserWorks(userInfoStore.userInfoData.id);
            console.log(res);
            if (res.status === "success") {
                //不要work_type为playground_chat和playground_agent的数据，并且md5相同的数据只保留一个
                const historyItems = res.data.filter((item: any) => item.work_type !== 'playground_chat' && item.work_type !== 'playground_agent');
                const worksMap = new Map();
                historyItems.forEach((item: any) => {
                    if (!worksMap.has(item.md5)) {
                        worksMap.set(item.md5, item);
                    }
                });
                setWorksList([...worksMap.values()]);
            }
        } catch (error) {
            console.error('Error getting all works', error)
        }
    }

    useEffect(() => {
        if (userInfoStore.userInfoData.id === 0) return;
        getAllWorks();
    }, [userInfoStore.userInfoData.id]);

    const WorkCard = ({ workItem }: any) => {
        let title, imageUrl, description;

        switch (workItem.work_type) {
            case 'music':
                title = workItem.work_info.name;
                imageUrl = workItem.work_info.cover_url;
                description = "音乐";
                break;

            case 'cyberAvatar':
                title = workItem.work_info.name;
                imageUrl = workItem.work_info.avatar_url;
                description = "赛博头像";
                break;

            case 'basicPost':
                title = workItem.work_info.title;
                imageUrl = workItem.work_info.imgUrl;
                description = "其他";
                break;

            case 'code':
                title = workItem.work_info.srcCode.slice(0, 15);
                imageUrl = '';
                description = "代码";
                break;

            default:
                return null;
        }
        //  根据不同的 work_type 显示不同的背景颜色
        const getBackgroundColor = () => {
            switch (workItem.work_type) {
                case 'music':
                    return '#FFD9E1';
                case 'cyberAvatar':
                    return '#D9E8FF';
                case 'basicPost':
                    return '#E8D9FF';
                case 'code':
                    return '#D9FFE8';
                // 处理更多类型  
                default:
                    return '#D9E8FF'; // 默认颜色  
            }
        }
        return (
            <div
                className={styles.worksItem}
            // 点击作品卡片跳转到分享页面,打开新的标签页

            >
                {imageUrl ? <img
                    src={imageUrl}
                    alt={title}
                    className={styles.worksItemImage}
                    onClick={() => window.open(`/share/${workItem.md5}`)}
                />
                    :
                    <div
                        className={styles.worksItemImage}
                        style={{ backgroundColor: getBackgroundColor() }}
                        onClick={() => window.open(`/share/${workItem.md5}`)}
                    />}
                <div className={styles.worksItemTitle}>{title}</div>
                <div className={styles.userName}>作者：{userInfoStore.userInfoData.name}</div>
            </div>
        );
    };

    return (
        <div className={styles.worksPage}>
            <div className={styles.goBack} onClick={() => navigate('/user')}>
                <img src={GoBackIcon} alt="go back" />
            </div>

            <div className={styles.content}>
                <div className={styles.mainTitle}>个人作品</div>
                <div className={styles.subTitle}>你所有的创作和创意表现都在这里哦</div>
                <div className={styles.worksBox}>
                    <div className={styles.worksListIcon}>
                        <img src={UserWorksListIcon} />
                    </div>
                    <div className={styles.title}>全部作品</div>
                    <div className={styles.worksList}>
                        {/* worksList 为空时显示提示 */}
                        {worksList.length === 0 && <div className={styles.noWorks}>暂无作品</div>}
                        {worksList.map((item: any) => {
                            return (
                                <WorkCard key={item.id} workItem={item} />
                            );
                        })}
                    </div>
                </div>

            </div>
        </div>
    )
}

export default observer(MyWorks);
