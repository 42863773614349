import { useState } from 'react';
import YinFuIcon from '@/assets/img/yinfu.svg';
import BoboMusicIcon from '@/assets/img/bobo_music.svg';
import styles from './genDescMusic.module.less';
import MagicWand from '../MagicWand/MagicWand';
import { Button, message } from 'antd';
import MultimediaAPI from '@/api/multimedia';
import { createSong, addSongToAlbum } from '@/api/playgroundMusic';
import { requestLLMCallV2 } from '@/services/requestLLMCall';
import { userInfoStore } from '@/store/userInfoStore';
import LLMCallRequestData from '@/base/LLMCallRequestData';
import SpeechToText from '@/components/SpeechToText/SpeechToText';
import MicrophoneIcon from '@/assets/img/playground/microphone.svg';

interface GenDescMusicProps {
    userId: number;
    getAlbumList: () => void;
};

const GenDescMusic: React.FC<GenDescMusicProps> = ({ userId, getAlbumList }) => {
    const api = new MultimediaAPI();
    const vendor = import.meta.env.VITE_GEN_MUSIC_VENDOR;
    const [description, setDescription] = useState('');
    const [apiStatus, setApiStatus] = useState(0); // 0: not started, 1: generating,
    //将生成的歌曲信息存入数据库
    const saveSong = async (name: string, lyrics: string, musicUrl: string,
        coverUrl: string, style: string) => {
        const songData = {
            id: 0,
            name: name,
            author_id: userId,
            lyrics: lyrics,
            music_url: musicUrl,
            cover_url: coverUrl,
            tags: style,
            play_count: 0,
            like_count: 0,
            update_time: 0
        }
        createSong(songData).then((res: any) => {
            if (res.status === 'success') {
                console.log('save song success:', res);
                addSongToAlbum(userId, res.data.id).then((res: any) => {
                    if (res.status === 'success') {
                        getAlbumList();
                        setApiStatus(0);
                    } else {
                        console.error('add song to album failed');
                        setApiStatus(0);
                    }
                });

            } else {
                console.error('save song failed');
                setApiStatus(0);
            }
        }).catch((error) => {
            console.error('save song error:', error);
            setApiStatus(0);
        })
    }
    const pollForSongUrl = async (songId: number) => {
        const intervalId = setInterval(async () => {
            try {
                const res: any = await api.getSongById(songId);
                if (vendor === "TIANGONG" && res.msg === "OK") {
                    if (res.data.state === 3) {
                        clearInterval(intervalId);
                        const backUpToCloudResponse: any = await api.backUpToCloud({
                            src_url: res.data.songs[0].mp3_url,
                            dest_fname: `${res.data.songs[0].name}.mp3`
                        });
                        if (backUpToCloudResponse.status === "success") {
                            saveSong(res.data.songs[0].name, res.data.songs[0].lyric.replace(/\[.*?\]/g, ' '), backUpToCloudResponse.data.status, res.data.songs[0].cover_url, res.data.songs[0].genres.join(','));
                        } else {
                            console.error('song url request error');
                            setApiStatus(0);
                            message.error('生成歌曲失败，请重试~');
                        }
                    } else if (res.data.state === 4) {
                        clearInterval(intervalId);
                        setApiStatus(0);
                        message.error('生成歌曲失败，请重试~');
                    }
                } else if (vendor === "SUNO" && res && res[0]?.status === 'complete') {
                    clearInterval(intervalId);
                    if (res && res[0]?.status === 'complete') {
                        clearInterval(intervalId);
                        saveSong(res[0].title, res[0].lyric.replace(/\[.*?\]/g, ' '), res[0].audio_url, res[0].image_url, res[0].tags);
                    } else {
                        console.error('song url request error');
                    }
                } else {
                    console.error('song url request error');
                }
            } catch (error) {
                setApiStatus(0);
                clearInterval(intervalId);
                console.error('getSongById:', error);
            }
        }, 3000); // 每3秒轮询一次  
    };


    const handleGenerate = async () => {
        if (description.trim() === "") {
            message.warning('请输入对歌曲的描述~');
            return;
        }
        if (apiStatus === 1) {
            message.warning('正在生成中，请稍等');
            return;
        }
        setApiStatus(1);

        const requestData = {
            request_id: "string",
            title: "",
            lyrics: "",
            genre: "",
            description: description,
        };
        try {
            const res: any = await api.sonoGenSong(requestData);
            if (res && res['status'] == 'success') {
                saveSong(res['data']['title'], res['data']['lyrics'].replace(/\[.*?\]/g, ' '), 
                            res['data']['audio_url'], res['data']['song_cover_url'], "");
            } else {
                setApiStatus(0);
                handleError();
            }
            
        } catch (error) {
            console.error('genMusic:', error);
            handleError();
        }
    };

    const handleError = () => {
        message.error('生成歌曲失败，请重试~');
        setApiStatus(0);
    }

    const handleMagicGen = (prompt: string, setHandler: any) => {
        //需要做rewrite
        const requestParams = LLMCallRequestData.genLLMCallParams(
            prompt,
            userInfoStore.userInfoData.id,
            userInfoStore.userInfoData.name,
            false,
        )

        requestLLMCallV2(requestParams).then((res) => {
            if (res) {
                console.log('res.content :', res.content);
                setHandler(res.content);
            } else {
                console.error('res is null');
            }
        }).catch((error) => {
            console.error('Error:', error);
        });

    };
    return (
        <div className={styles.inspirationGen}>
            <div className={styles.inspirationHeader}>
                <div className={styles.boboMusicIcon}>
                    <img src={BoboMusicIcon} />
                </div>
                <div className={styles.headerTitle}>BoBo音乐</div>
            </div>
            <div className={styles.genArea}>
                <textarea
                    className={styles.input}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    onKeyDown={() => { }}
                    placeholder='请输入歌曲的描述~~'
                />
                <div className={styles.magicGenBtn}>
                    <SpeechToText
                        setInputMessage={setDescription}
                        inputMessage={description}
                        microphoneIcon={MicrophoneIcon}
                    />
                    <MagicWand onMagicGenerate={() => {
                        handleMagicGen("我想写一首歌，帮我生成一段描述，字数控制在100以内", setDescription);
                    }} />
                </div>
            </div>
            <Button
                className={styles.genMusicButton}
                onClick={handleGenerate}
                loading={apiStatus === 1}
                icon={<img src={YinFuIcon} />}
            >
                {["开始创作", "创作中（大概需要2~3分钟）"][apiStatus]}
            </Button>
        </div>
    )
}

export default GenDescMusic;